@font-face {
    font-family: "regular-font";
    src: url("../../public/assets/font/FivoSans/FivoSans-Regular.otf");
    font-weight: normal;
}

@font-face {
    font-family: "medium-font";
    src: url("../../public/assets/font/FivoSans/FivoSans-Medium.otf");
    font-weight: normal;
}

@font-face {
    font-family: "semi-bold-font";
    src: url("../../public/assets/font/FivoSans/FivoSans-Heavy.otf");
    font-weight: normal;
}

@font-face {
    font-family: "bold-font";
    src: url("../../public/assets/font/FivoSans/FivoSans-Bold.otf");
    font-weight: normal;
}