.for-mobile {
    padding-top: 100px;
    padding-left: 15px;
    overflow-y: auto;
    position: relative;

    .mobile-logo {
        position: absolute;
        top: 20px;
        left: 20px;
    }

    a {
        color: #fff !important;
        margin: 20px 0;
    }

    .dowpdown-section-btn1 {
        margin-left: 0 !important;
        padding-left: 0;
        margin-top: 20px;
        color: #fff;

    }

    .dowpdown-section-btn2 {
        img {
            width: 40px;
            height: 40px;
        }
    }

    .dropdown-menu {
        background-color: transparent;

        .dropdown-item {

            margin: 0 !important;
        }

    }

    .login {
        margin-left: 0 !important;
        margin-right: 15px;
    }
}

.dropdown-section {
    &-myaccount {}

    &-btn2 {
        width: 60px;
        height: 60px;
        padding: 0;

        img {
            width: 100%;
            border-radius: 50%;
        }
    }
}

.menu-toggle {
    border: 2px solid #019D5F;
    border-radius: 4px;
    font-size: 25px;
    color: #019D5F;

    &:focus {
        border: 2px solid #019D5F;
        outline: none;
    }
}

#sidebar-mobile {
    display: block;
    position: absolute;
    top: 0px;
    left: -200px;
    width: 200px;
    height: 100vh;
    background: #151719;
    transition: all 300ms linear;

    &.active {
        left: 0;
    }
}