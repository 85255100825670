.loader-container {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(51, 51, 51, 0.634);
    z-index: 9999;
}

.loading-text {
    position: absolute;
    top: 59%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.book {
    border: 4px solid #FFFFFF;
    width: 60px;
    height: 45px;
    position: relative;
    perspective: 150px;
}

.page {
    display: block;
    width: 30px;
    height: 45px;
    border: 4px solid #FFFFFF;
    border-left: 1px solid #019D5F;
    margin: 0;
    position: absolute;
    right: -4px;
    top: -4px;
    overflow: hidden;
    background: #019D5F;
    transform-style: preserve-3d;
    -webkit-transform-origin: left center;
    transform-origin: left center;
}

.book .page:nth-child(1) {
    -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.6s infinite;
    animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.6s infinite;
}

.book .page:nth-child(2) {
    -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.45s infinite;
    animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.45s infinite;
}

.book .page:nth-child(3) {
    -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.2s infinite;
    animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.2s infinite;
}


/* Page turn */

@-webkit-keyframes pageTurn {
    0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }

    20% {
        background: #019D5F;
    }

    40% {
        background: rebeccapurple;
        -webkit-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
    }

    100% {
        background: rebeccapurple;
        -webkit-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
    }
}

@keyframes pageTurn {
    0% {
        transform: rotateY(0deg);
    }

    20% {
        background: #019D5F;
    }

    40% {
        background: #019D5F;
        ;
        transform: rotateY(-180deg);
    }

    100% {
        background: #019D5F;
        transform: rotateY(-180deg);
    }
}


/* Dots */

@-webkit-keyframes Dots {
    0% {
        content: "";
    }

    33% {
        content: ".";
    }

    66% {
        content: "..";
    }

    100% {
        content: "...";
    }
}

@keyframes Dots {
    0% {
        content: "";
    }

    33% {
        content: ".";
    }

    66% {
        content: "..";
    }

    100% {
        content: "...";
    }
}