@import url('./styles/bootstrap/bootstrap.css');

@import url('./styles/fonts.scss');
@import url('./styles/custom-css.scss');
@import url('./styles/accordian.scss');

body {
    font-family: 'regular-font';
    min-height: 100%;
}

html {
    position: relative;
    min-height: 100%;
}

.modal-backdrop.show {
    opacity: 0.9;
}

.down-btn {
    bottom: 0;
    right: 20px;
    font-size: 15px;
    display: flex;
    padding: 5px;
    align-items: center;
}