$primary: #019D5F;
$success: #2eb82e;
$danger: #ff3300;

$secondry: #868686;

.logo {
    width: 100px;
}

.banner-row {
    background: url('../../public/assets/images/Banner_bg.jpg') no-repeat;
    background-size: cover;
    height: 30rem;
}

.find-book-heading {
    font-family: "bold-font";
}

.heading {
    span {
        font-family: "bold-font";
    }
}

.menu-bar {
    font-family: "medium-font";
    color: #000;
}

.page-scrolled {
    transition: 0.5s all;
    background: linear-gradient(90deg, rgba(251, 236, 232, 1) 0%, rgba(235, 231, 243, 1) 50%) !important;
    background: -webkit-linear-gradient(90deg, rgba(251, 236, 232, 1) 0%, rgba(235, 231, 243, 1) 50%) !important;
    padding-top: 0;
}

.book-card {
    .author-name {
        opacity: 70%;
    }

    .book-name {
        font-family: "medium-font";
        text-overflow: ellipsis;
        /* IE, Safari (WebKit) */
        overflow: hidden;
        /* don't show excess chars */
        white-space: nowrap;
    }

    .book-price {
        color: $primary;
    }

    transition: 0.5s all;

    .product-icon {
        display: none;
        opacity: 0;
    }

    &:hover {
        border-radius: 5px;
        box-shadow: 0 0 5px #868686;

        .product-icon {
            display: block;
            opacity: 1;
            width: 25px;
            height: 25px;
            top: 5px;
            background-color: #fff !important;
            padding: 2px;

            &.right {
                right: 5px;
            }

            &.left {
                left: 5px;
            }
        }
    }
}

.cart-count {
    top: -15px;
    right: -7px;
    padding-top: 2px;
    width: 20px;
    height: 20px;
    font-size: 12px;
}

.inner-pages {
    margin-top: 100px;
    margin-bottom: 5rem;
}

.footer-section {
    background-color: #393939;
    // position: absolute;
    // bottom: 0;
}

.close-btn {
    right: 10px;
}

.checkbox-item {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    font-size: .8123rem;
    color: #000;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .checkbox {
        position: relative;
        height: 22px;
        width: 22px;
        border-radius: 5px;
        background-color: transparent;
        border: 1px solid $secondry;
        transition: all 0.15s;

        &:after {
            content: "";
            position: absolute;
            top: 12px;
            left: 1.5px;
            width: 0px;
            height: 0px;
            border: solid $secondry;
            border-width: 2px 2px 0 0;
            opacity: 0;
            transform-origin: left top;
            transform: scaleX(-1) rotate(135deg);
            transition: width 0.08s ease 0.1s, height 0.1s ease, border-color 0.3s ease,
                opacity 0.1s ease 0.2s, border-width 0.1s ease;
        }


    }

    input:checked~.checkbox:after {
        opacity: 1;
        width: 7px;
        height: 15px;
        border-color: $primary;
        transition: width 0.1s ease, height 0.08s ease 0.1s, border-color 0.3s ease,
            border-width 0.1s ease;
    }

    input:focus-visible~.checkbox {
        border: 1px solid $primary;
    }

    &:hover input~.checkbox {
        border: 1px solid $primary;
    }
}

.clickable {
    cursor: pointer;
}

.product {
    &-heading {
        color: #000;
        font-size: 2rem;
        font-weight: 600;
    }

    &-short-detail {
        color: #6B6B6B;
    }
}

.profile-avtar {
    &:active {
        // outline: none;
        box-shadow: none;
    }

    img {
        width: 50px;
        height: 50px;
    }
}

.account-sidebar {
    &-item {
        &-heading {
            font-weight: 700;
            margin-bottom: 15px;

            i {
                margin-right: 10px;
                font-size: 25px;
            }
        }

        ul {
            list-style: none;
            padding-left: 29px;
            border-bottom: 1px solid #DFDFDF;

            li {
                margin: 7px 0;
            }
        }
    }
}

.icon-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.text-decoration-line-through {
    text-decoration: line-through !important;
}

.radio-ui {
    margin: 0.5rem;

    input[type="radio"] {
        position: absolute;
        opacity: 0;

        +.radio-label {
            cursor: pointer;

            &:before {
                content: '';
                background: #fff;
                border-radius: 100%;
                border: 1px solid $primary;
                display: inline-block;
                width: 1.4em;
                height: 1.4em;
                position: relative;
                top: -0.2em;
                margin-right: 1em;
                vertical-align: top;
                cursor: pointer;
                text-align: center;
                transition: all 250ms ease;
            }
        }

        &:checked {
            +.radio-label {
                &:before {
                    background-color: $primary;
                    box-shadow: inset 0 0 0 4px #fff;
                }
            }
        }

        &:focus {
            +.radio-label {
                &:before {
                    outline: none;
                    border-color: $primary;
                }
            }
        }

        &:disabled {
            +.radio-label {
                &:before {
                    box-shadow: inset 0 0 0 4px $primary;
                    border-color: darken($primary, 25%);
                    background: darken($primary, 25%);
                }
            }
        }

        +.radio-label {
            &:empty {
                &:before {
                    margin-right: 0;
                }
            }
        }
    }
}

.product-upload-frame {
    width: 250px;
    height: 380px;
    position: relative;
    border-radius: 5px;

    img {
        width: 100%;
        height: 380px;
        object-fit: cover;
        border-radius: 5px;
    }

    .placeholder {
        position: relative;
        width: 100%;
        height: 100%;
        border: 2px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
        display: block;

        img {
            position: absolute;
            width: 100px;
            height: 100px;
            background-image: url('../../public/assets/images/upload-placeholder.png');
            background-size: 100%;
            z-index: 1;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 50%;
        }
    }


}

.disabled {
    opacity: .5;
    cursor: not-allowed !important;
}

.dropdown-icon {
    transition: all .5s;
}

.rotate-drop {
    transition: all .5s;
    transform: rotate(180deg);
}

.edit-class {
    .ql-editor {
        min-height: 150px;
        max-height: 300px;
        overflow: auto;
    }
}

@media (max-width: 992px) {
    .navbar-collapse.collapse.show {
        background: linear-gradient(90deg, rgb(251, 236, 232) 0%, rgb(235, 231, 243) 50%);
        background: -webkit-linear-gradient(90deg, rgb(251, 236, 232) 0%, rgb(235, 231, 243) 50%);
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        padding: 0.5rem;

        .profile-avtar {
            margin-left: 0 !important;
            padding-left: 0 !important;
        }

        a {
            text-align: left;
            // padding-left: 15px;
            border-bottom: 1px solid rgba(70, 70, 70, 0.338);
        }
    }
}

.lh-normal {
    line-height: 1;
}

.product-thumb {
    height: 280px;
    object-fit: cover;
    box-shadow: 0 1px 5px #c2c2c2;
}

.cross-icon {
    width: 15px;
    height: 15px;
    display: inline-flex;
    line-height: 1;
    padding: 0 !important;
    text-align: center;

}

.message-box {
    display: flex;
    justify-content: center;
    padding-top: 20vh;
    padding-bottom: 20vh;
}

.approval-status {
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgb(1 157 95 / 30%);
    color: #ffffff;
    padding-top: 40%;
    font-weight: 600;
    font-size: 30px;
    text-shadow: -1px -1px 0 #01492c, 1px -1px 0 #01492c, -1px 1px 0 #01492c, 1px 1px 0 #01492c;
}

.success-container {
    background: white;
    width: 90%;
    box-shadow: 5px 5px 10px grey;
    text-align: center;
}

.confirm-green-box {
    width: 100%;
    height: 140px;
    background: #d7f5da;
}


.monserrat-font {
    letter-spacing: 2px;
}

.checkmark-succ {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    padding: 15px;
}

.animated-check {
    height: 4em;
    width: 4em;
    border: 2px solid #7ac142;
    border-radius: 50%;
    padding: 7px;
}

.animated-check path {
    fill: none;
    stroke: #7ac142;
    stroke-width: 3;
    stroke-dasharray: 23;
    stroke-dashoffset: 23;
    animation: draw 2s infinite forwards;
    stroke-linecap: round;
    stroke-linejoin: round
}

@keyframes draw {
    to {
        stroke-dashoffset: 0
    }
}

.visually-hidden {
    display: none;
}


.order-type {
    label {
        position: relative;
        line-height: 1;
        cursor: pointer;
        padding: 10px 15px 10px 60px;
        height: 60px;
        align-items: center;
        display: grid;

        &::before {
            content: '';
            width: 20px;
            height: 20px;
            border: 1px solid #6B6B6B;
            border-radius: 50%;
            position: absolute;
            left: 10%;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    input {
        &:checked~label {
            color: #fff;
            background-color: #535755;

            &::before {
                content: '✓';
                border: 1px solid #f9f9f9;
                background-color: #535755;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.inner-search {
    .rbt-menu.dropdown-menu.show {
        display: none !important;
    }
}

.form-check {
    position: relative;


    /* Hide the browser's default checkbox */
    .check-box-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #eee;
        cursor: pointer;
        border: 1px solid #000;
    }

    /* On mouse-over, add a grey background color */
    .check-box-container:hover input~.checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .check-box-container input:checked~.checkmark {
        background-color: #2196F3;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .check-box-container input:checked~.checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .check-box-container .checkmark:after {
        left: 6px;
        top: 1px;
        width: 6px;
        height: 14px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}